import { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { db, functions } from '../../firebase/config';
import { doc, getDoc, updateDoc, collection, query, where, getDocs, addDoc, deleteDoc } from 'firebase/firestore';
import { useAuth } from '../../contexts/AuthContext';
import LoadingScreen from '../ui/LoadingScreen';
import { toast } from 'react-hot-toast';
import { httpsCallable } from 'firebase/functions';

export default function OrganizationSettings() {
  const { orgId } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [organization, setOrganization] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [organizationName, setOrganizationName] = useState('');

  // Form állapotok
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [newUserEmail, setNewUserEmail] = useState('');
  const [addingUser, setAddingUser] = useState(false);
  const [userError, setUserError] = useState('');

  useEffect(() => {
    async function fetchOrganization() {
      try {
        const orgDoc = await getDoc(doc(db, 'organizations', orgId));
        if (orgDoc.exists()) {
          const orgData = orgDoc.data();
          setOrganization(orgData);
          setOrganizationName(orgData.name || '');
          setName(orgData.name || '');
          setDescription(orgData.description || '');
        } else {
          setError('Szervezet nem található');
        }
      } catch (err) {
        setError('Hiba történt az adatok betöltésekor');
        console.error(err);
      } finally {
        setLoading(false);
      }
    }

    fetchOrganization();
  }, [orgId]);

  async function handleSubmit(e) {
    e.preventDefault();
    setError('');

    try {
      await updateDoc(doc(db, 'organizations', orgId), {
        name,
        description,
        updatedAt: new Date(),
      });
      navigate(`/organization/${orgId}`);
    } catch (err) {
      setError('Hiba történt a mentés során');
      console.error(err);
    }
  }

  async function handleAddUser(e) {
    e.preventDefault();
    setAddingUser(true);
    setUserError('');

    try {
      // Admin jogosultság ellenőrzése
      const isCurrentUserAdmin = (organization.admins || [organization.adminId]).includes(currentUser.uid);
      if (!isCurrentUserAdmin) {
        setUserError('Csak a szervezet adminisztrátora adhat hozzá új tagokat');
        return;
      }

      // Felhasználó keresése email alapján
      const usersQuery = query(
        collection(db, 'users'),
        where('email', '==', newUserEmail.toLowerCase())
      );
      const userSnapshot = await getDocs(usersQuery);

      if (userSnapshot.empty) {
        // Ha nem található a felhasználó, létrehozunk egy meghívót
        const inviteData = {
          email: newUserEmail.toLowerCase(),
          organizationId: orgId,
          organizationName: organization.name,
          invitedBy: currentUser.uid,
          invitedByEmail: currentUser.email,
          status: 'pending',
          createdAt: new Date().toISOString(),
          expiresAt: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString() // 7 nap
        };

        let inviteRef;
        try {
          // Meghívó mentése az adatbázisba
          inviteRef = await addDoc(collection(db, 'invitations'), inviteData);
          
          const sendInvitation = httpsCallable(functions, 'sendInvitation');
          const result = await sendInvitation({ 
            email: newUserEmail,
            organizationName: organization.name,
            invitedByEmail: currentUser.email,
            invitationId: inviteRef.id
          });

          if (result.data && result.data.success) {
            toast.success('Meghívó elküldve!');
            setNewUserEmail('');
            return;
          } else {
            throw new Error(result.data?.message || 'Nem sikerült elküldeni a meghívót');
          }
        } catch (error) {
          console.error('Error sending invitation:', error);
          if (inviteRef) {
            await deleteDoc(inviteRef);
          }
          setUserError('Hiba történt a meghívó küldésekor: ' + error.message);
          return;
        }
      }

      const userData = userSnapshot.docs[0];
      const userId = userData.id;

      if (organization.members.includes(userId)) {
        setUserError('Ez a felhasználó már tagja a szervezetnek');
        return;
      }

      // Felhasználó hozzáadása a szervezethez
      const updatedMembers = [...organization.members, userId];
      await updateDoc(doc(db, 'organizations', orgId), {
        members: updatedMembers,
        updatedAt: new Date().toISOString()
      });

      setOrganization(prev => ({
        ...prev,
        members: updatedMembers
      }));
      setNewUserEmail('');
      
      toast.success('Felhasználó sikeresen hozzáadva!');

    } catch (err) {
      console.error('Error:', err);
      setUserError('Hiba történt: ' + err.message);
    } finally {
      setAddingUser(false);
    }
  }

  async function handleUpdateRole(userId, newRole) {
    try {
      // Ellenőrizzük, hogy a jelenlegi felhasználó admin-e
      const isCurrentUserAdmin = (organization.admins || [organization.adminId]).includes(currentUser.uid);
      
      if (!isCurrentUserAdmin) {
        setError('Csak a szervezet adminisztrátora módosíthat jogosultságokat');
        toast.error('Nincs jogosultságod a művelet végrehajtásához');
        return;
      }

      // Ne engedjük, hogy az admin elvegye saját magától az admin jogot
      if (userId === currentUser.uid && newRole !== 'admin') {
        setError('Nem veheted el saját magadtól az admin jogot');
        toast.error('Nem veheted el saját magadtól az admin jogot');
        return;
      }

      // Inicializáljuk az admins tömböt, ha még nem létezik
      const currentAdmins = organization.admins || [organization.adminId];
      
      // Frissítsük az adminok listáját
      let updatedAdmins = [...currentAdmins];
      if (newRole === 'admin' && !updatedAdmins.includes(userId)) {
        updatedAdmins.push(userId);
      } else if (newRole === 'member') {
        updatedAdmins = updatedAdmins.filter(id => id !== userId);
      }

      const updates = {
        admins: updatedAdmins,
        updatedAt: new Date().toISOString()
      };

      await updateDoc(doc(db, 'organizations', orgId), updates);
      
      setOrganization(prev => ({
        ...prev,
        admins: updatedAdmins
      }));

      toast.success('Jogosultság sikeresen módosítva!');

    } catch (err) {
      console.error('Error updating role:', err);
      setError('Hiba történt a jogosultság módosításakor');
    }
  }

  const handleRemoveUser = async (userId) => {
    if (!window.confirm('Biztosan el szeretnéd távolítani ezt a felhasználót a szervezetből?')) {
      return;
    }

    try {
      // Ellenőrizzük, hogy a jelenlegi felhasználó admin-e
      const isCurrentUserAdmin = (organization.admins || [organization.adminId]).includes(currentUser.uid);
      
      if (!isCurrentUserAdmin) {
        toast.error('Csak a szervezet adminisztrátora távolíthat el tagokat');
        return;
      }

      // Ne engedjük, hogy az admin eltávolítsa saját magát
      if (userId === currentUser.uid) {
        toast.error('Nem távolíthatod el saját magad a szervezetből');
        return;
      }

      // Frissítjük a szervezet tagjainak listáját
      const updatedMembers = organization.members.filter(id => id !== userId);
      
      // Ha admin volt, az admin listából is eltávolítjuk
      const updatedAdmins = (organization.admins || []).filter(id => id !== userId);

      await updateDoc(doc(db, 'organizations', orgId), {
        members: updatedMembers,
        admins: updatedAdmins,
        updatedAt: new Date().toISOString()
      });

      setOrganization(prev => ({
        ...prev,
        members: updatedMembers,
        admins: updatedAdmins
      }));

      toast.success('Felhasználó sikeresen eltávolítva a szervezetből');

    } catch (error) {
      console.error('Hiba a felhasználó eltávolításakor:', error);
      toast.error('Hiba történt a felhasználó eltávolítása során');
    }
  };

  if (loading) return <LoadingScreen />;
  if (error) return <div className="text-red-600">{error}</div>;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {/* Breadcrumb */}
      <nav className="mb-8 bg-white shadow-sm rounded-lg px-4 py-2" aria-label="Breadcrumb">
        <ol className="flex items-center space-x-2 text-[8pt]">
          <li className="flex items-center">
            <Link 
              to="/" 
              className="text-indigo-600 hover:text-indigo-900 transition-colors duration-150 ease-in-out flex items-center"
            >
              <svg 
                className="h-3 w-3 mr-1" 
                fill="currentColor" 
                viewBox="0 0 20 20"
              >
                <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
              </svg>
              Kezdőlap
            </Link>
          </li>
          <li>
            <svg className="h-3 w-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
            </svg>
          </li>
          <li className="flex items-center">
            <Link 
              to="/organizations" 
              className="text-indigo-600 hover:text-indigo-900 transition-colors duration-150 ease-in-out"
            >
              Szervezetek
            </Link>
          </li>
          <li>
            <svg className="h-3 w-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
            </svg>
          </li>
          <li className="flex items-center">
            <Link 
              to={`/organization/${orgId}`}
              className="text-indigo-600 hover:text-indigo-900 transition-colors duration-150 ease-in-out"
            >
              {organization?.name || 'Betöltés...'}
            </Link>
          </li>
          <li>
            <svg className="h-3 w-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
            </svg>
          </li>
          <li className="text-gray-500 font-medium">
            Beállítások
          </li>
        </ol>
      </nav>

      <div className="md:flex md:items-center md:justify-between mb-6">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            Szervezet beállítások
          </h2>
        </div>
      </div>

      <div className="bg-white shadow sm:rounded-lg">
        <form onSubmit={handleSubmit} className="space-y-6 p-6">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
              Szervezet neve
            </label>
            <input
              type="text"
              name="name"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
          </div>

          <div>
            <label htmlFor="description" className="block text-sm font-medium text-gray-700">
              Leírás
            </label>
            <textarea
              id="description"
              name="description"
              rows={3}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>

          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={() => navigate(`/organization/${orgId}`)}
              className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Mégse
            </button>
            <button
              type="submit"
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Mentés
            </button>
          </div>
        </form>
      </div>

      <div className="mt-8 bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Felhasználó hozzáadása
          </h3>
          <div className="mt-2 max-w-xl text-sm text-gray-500">
            <p>Add meg a felhasználó email címét a hozzáadáshoz.</p>
          </div>
          <form onSubmit={handleAddUser} className="mt-5 sm:flex sm:items-center">
            <div className="w-full sm:max-w-xs">
              <input
                type="email"
                value={newUserEmail}
                onChange={(e) => setNewUserEmail(e.target.value)}
                placeholder="pelda@email.com"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <button
              type="submit"
              disabled={addingUser}
              className="mt-3 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              {addingUser ? 'Hozzáadás...' : 'Hozzáadás'}
            </button>
          </form>
          {userError && (
            <div className="mt-2 text-sm text-red-600">
              {userError}
            </div>
          )}
        </div>
      </div>

      <UserList 
        organization={organization} 
        onUpdateRole={handleUpdateRole}
        currentUser={currentUser}
        handleRemoveUser={handleRemoveUser}
      />
    </div>
  );
}

// UserList komponens az OrganizationSettings-en belül
function UserList({ organization, onUpdateRole, currentUser, handleRemoveUser }) {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    async function fetchUsers() {
      try {
        const userPromises = organization.members.map(async (userId) => {
          const userDoc = await getDoc(doc(db, 'users', userId));
          return {
            id: userId,
            ...userDoc.data(),
            role: (organization.admins || [organization.adminId]).includes(userId) ? 'admin' : 'member'
          };
        });
        const userList = await Promise.all(userPromises);
        setUsers(userList);
      } catch (err) {
        setError('Hiba történt a felhasználók betöltésekor');
      } finally {
        setLoading(false);
      }
    }

    if (organization?.members) {
      fetchUsers();
    }
  }, [organization]);

  if (loading) return <div>Betöltés...</div>;
  if (error) return <div className="text-red-600">{error}</div>;

  return (
    <div className="mt-8 bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Felhasználók
        </h3>
        <div className="mt-2 max-w-xl text-sm text-gray-500">
          <p>A szervezet tagjai.</p>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-col sm:items-start">
          <div className="mt-3 sm:mt-0 sm:w-full sm:flex sm:flex-row">
            <div className="flex-1 min-w-0">
              <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Név
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Email
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Jogosultság
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Műveletek
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {users.map((user) => (
                            <tr key={user.id}>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div className="text-sm font-medium text-gray-900">
                                  {user.name || user.email}
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div className="text-sm text-gray-500">{user.email}</div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                  user.role === 'admin' ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-800'
                                }`}>
                                  {user.role === 'admin' ? 'Admin' : 'Tag'}
                                </span>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                <div className="flex items-center justify-end space-x-2">
                                  {(organization.admins?.includes(currentUser.uid) || organization.adminId === currentUser.uid) 
                                   && user.id !== currentUser.uid && (
                                    <button
                                      onClick={() => onUpdateRole(user.id, user.role === 'admin' ? 'member' : 'admin')}
                                      className={`p-1.5 rounded-full hover:bg-gray-100 flex items-center ${
                                        user.role === 'admin' 
                                          ? 'text-green-600 hover:text-green-700' 
                                          : 'text-gray-400 hover:text-indigo-600'
                                      }`}
                                      title={user.role === 'admin' ? 'Admin jogok elvétele' : 'Admin jogok adása'}
                                    >
                                      <svg 
                                        className="h-5 w-5" 
                                        fill="none" 
                                        viewBox="0 0 24 24" 
                                        stroke="currentColor"
                                      >
                                        {user.role === 'admin' ? (
                                          <path 
                                            strokeLinecap="round" 
                                            strokeLinejoin="round" 
                                            strokeWidth={2} 
                                            d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" 
                                          />
                                        ) : (
                                          <path 
                                            strokeLinecap="round" 
                                            strokeLinejoin="round" 
                                            strokeWidth={2} 
                                            d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z" 
                                          />
                                        )}
                                      </svg>
                                    </button>
                                  )}

                                  {(organization.admins?.includes(currentUser.uid) || organization.adminId === currentUser.uid) 
                                   && user.id !== currentUser.uid && (
                                    <button
                                      onClick={() => handleRemoveUser(user.id)}
                                      className="p-1.5 text-gray-400 hover:text-red-600 rounded-full hover:bg-red-50 flex items-center"
                                      title="Felhasználó eltávolítása"
                                    >
                                      <svg 
                                        className="h-5 w-5" 
                                        fill="none" 
                                        viewBox="0 0 24 24" 
                                        stroke="currentColor"
                                      >
                                        <path 
                                          strokeLinecap="round" 
                                          strokeLinejoin="round" 
                                          strokeWidth={2} 
                                          d="M13 7a4 4 0 11-8 0 4 4 0 018 0zM9 14a6 6 0 00-6 6v1h12v-1a6 6 0 00-6-6zM21 12h-6" 
                                        />
                                      </svg>
                                    </button>
                                  )}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 