import { useState, useEffect } from 'react';
import { db } from '../../firebase/config';
import { collection, query, where, getDocs, getDoc, doc, updateDoc } from 'firebase/firestore';
import { useAuth } from '../../contexts/AuthContext';
import { Link } from 'react-router-dom';
import Avatar from '../common/Avatar';
import { toast } from 'react-hot-toast';

// Előre definiált színek a projektekhez
export const PROJECT_COLORS = {
  blue: 'bg-blue-100 border-blue-300',
  darkBlue: 'bg-blue-200 border-blue-400',
  green: 'bg-green-100 border-green-300',
  darkGreen: 'bg-green-200 border-green-400',
  yellow: 'bg-yellow-100 border-yellow-300',
  darkYellow: 'bg-yellow-200 border-yellow-400',
  cyberYellow: 'bg-yellow-300 border-yellow-500',
  red: 'bg-red-100 border-red-300',
  darkRed: 'bg-red-200 border-red-400',
  purple: 'bg-purple-100 border-purple-300',
  darkPurple: 'bg-purple-200 border-purple-400',
  pink: 'bg-pink-100 border-pink-300',
  darkPink: 'bg-pink-200 border-pink-400',
  indigo: 'bg-indigo-100 border-indigo-300',
  darkIndigo: 'bg-indigo-200 border-indigo-400',
  orange: 'bg-orange-100 border-orange-300',
  darkOrange: 'bg-orange-200 border-orange-400',
  teal: 'bg-teal-100 border-teal-300',
  darkTeal: 'bg-teal-200 border-teal-400',
  cyan: 'bg-cyan-100 border-cyan-300',
  darkCyan: 'bg-cyan-200 border-cyan-400',
  lime: 'bg-lime-100 border-lime-300',
  darkLime: 'bg-lime-200 border-lime-400',
  emerald: 'bg-emerald-100 border-emerald-300',
  darkEmerald: 'bg-emerald-200 border-emerald-400',
  rose: 'bg-rose-100 border-rose-300',
  darkRose: 'bg-rose-200 border-rose-400',
  sky: 'bg-sky-100 border-sky-300',
  darkSky: 'bg-sky-200 border-sky-400',
};

// Segédfüggvény a dátum formázásához
const formatDate = (date) => {
  if (!date) return null;
  
  try {
    // Ha Timestamp objektum
    if (date?.toDate) {
      return date.toDate().toLocaleDateString('hu-HU', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    }
    
    // Ha ISO string vagy más dátum string
    return new Date(date).toLocaleDateString('hu-HU', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  } catch (error) {
    console.error('Dátum formázási hiba:', error);
    return null;
  }
};

const PROJECT_STATUSES = {
  active: {
    label: 'Aktív',
    class: 'bg-green-100 text-green-800',
    icon: '🟢'
  },
  on_hold: {
    label: 'Függőben',
    class: 'bg-yellow-100 text-yellow-800',
    icon: '🟡'
  },
  completed: {
    label: 'Lezárt',
    class: 'bg-blue-100 text-blue-800',
    icon: '🔵'
  },
  cancelled: {
    label: 'Megszakítva',
    class: 'bg-red-100 text-red-800',
    icon: '🔴'
  }
};

// Add helper function for HTML rendering
const renderHTML = (html) => {
  return { __html: html };
};

// Adjuk hozzá ezt a segédfüggvényt a fájl elejére
const getDueDateStatus = (dueDate) => {
  if (!dueDate) return null;
  
  const today = new Date();
  const due = dueDate instanceof Date ? dueDate : dueDate.toDate();
  const daysUntilDue = Math.ceil((due - today) / (1000 * 60 * 60 * 24));

  if (daysUntilDue < 0) {
    return { 
      label: 'Lejárt', 
      class: 'text-red-700 bg-red-100',
      textClass: 'text-red-700',
      icon: '⚠️' 
    };
  }
  if (daysUntilDue <= 7) {
    return { 
      label: 'Hamarosan', 
      class: 'text-yellow-700 bg-yellow-100',
      textClass: 'text-yellow-700',
      icon: '⏰' 
    };
  }
  return { 
    label: 'Határidős', 
    class: 'text-green-700 bg-green-100',
    textClass: 'text-green-700',
    icon: '📅' 
  };
};

// Placeholder komponens a töltődő profilképekhez
const AvatarSkeleton = () => (
  <div className="animate-pulse flex items-center space-x-2">
    <div className="h-8 w-8 bg-gray-200 rounded-full"></div>
  </div>
);

// Projekt státusz badge komponens
const ProjectStatusBadge = ({ status }) => {
  const statusConfig = {
    active: {
      label: 'Aktív',
      className: 'bg-gradient-to-r from-green-500 to-green-600 text-white'
    },
    on_hold: {
      label: 'Függőben',
      className: 'bg-gradient-to-r from-yellow-400 to-yellow-500 text-white'
    },
    completed: {
      label: 'Lezárt',
      className: 'bg-gradient-to-r from-blue-500 to-blue-600 text-white'
    },
    cancelled: {
      label: 'Megszakítva',
      className: 'bg-gradient-to-r from-red-500 to-red-600 text-white'
    }
  };

  const config = statusConfig[status] || statusConfig.active;

  return (
    <span className={`inline-flex items-center px-3 py-1 rounded-full text-xs font-medium shadow-sm ${config.className}`}>
      {config.label}
    </span>
  );
};

const STATUS_FILTERS = [
  { value: 'all', label: 'Összes', icon: '🔍' },
  { value: 'active', label: 'Aktív', icon: '🟢' },
  { value: 'on_hold', label: 'Függőben', icon: '🟡' },
  { value: 'completed', label: 'Lezárt', icon: '🔵' },
  { value: 'cancelled', label: 'Megszakítva', icon: '🔴' }
];

// Határidő badge komponens
const DeadlineBadge = ({ deadline }) => {
  const getDaysUntil = (date) => {
    const today = new Date();
    const dueDate = date.toDate();
    const diffTime = dueDate - today;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const daysLeft = getDaysUntil(deadline);
  
  let badgeStyle = '';
  let icon = '';
  let label = '';

  if (daysLeft < 0) {
    badgeStyle = 'bg-gradient-to-r from-red-500 to-red-600 text-white animate-pulse';
    icon = '⚠️';
    label = 'Lejárt';
  } else if (daysLeft <= 3) {
    badgeStyle = 'bg-gradient-to-r from-red-400 to-red-500 text-white';
    icon = '🔥';
    label = 'Sürgős';
  } else if (daysLeft <= 7) {
    badgeStyle = 'bg-gradient-to-r from-yellow-400 to-yellow-500 text-white';
    icon = '⏰';
    label = 'Hamarosan';
  } else {
    badgeStyle = 'bg-gradient-to-r from-green-500 to-green-600 text-white';
    icon = '📅';
  }

  return (
    <span className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium shadow-sm ${badgeStyle}`}>
      <span className="mr-1">{icon}</span>
      {deadline.toDate().toLocaleDateString()}
      {label && <span className="ml-1.5 text-xs bg-white bg-opacity-20 px-1.5 py-0.5 rounded-full">{label}</span>}
    </span>
  );
};

export default function ProjectList({ organizationId }) {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [statusFilter, setStatusFilter] = useState('active');
  const [viewMode, setViewMode] = useState('list');
  const [projectMembers, setProjectMembers] = useState({});
  const [users, setUsers] = useState({});
  const [pinnedProjects, setPinnedProjects] = useState(new Set());
  const [usersLoading, setUsersLoading] = useState(true); // Új állapot a felhasználók betöltéséhez

  useEffect(() => {
    async function fetchProjects() {
      try {
        const projectsRef = collection(db, 'projects');
        const q = query(projectsRef, where('organizationId', '==', organizationId));
        const querySnapshot = await getDocs(q);
        
        const projectsData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        
        setProjects(projectsData);
      } catch (error) {
        console.error('Hiba a projektek betöltésekor:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchProjects();
  }, [organizationId]);

  useEffect(() => {
    let filtered = projects;

    // Keresési szűrő
    if (searchTerm) {
      filtered = filtered.filter(project => 
        project.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        project.code.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Státusz szűrő - most az 'active' az alapértelmezett
    if (statusFilter !== 'all') {
      filtered = filtered.filter(project => project.status === statusFilter);
    }

    setFilteredProjects(filtered);
  }, [projects, searchTerm, statusFilter]);

  useEffect(() => {
    console.log('Project Members:', projectMembers);
    console.log('Users:', users);
  }, [projectMembers, users]);

  const fetchProjectMembers = async () => {
    try {
      const newProjectMembers = {};
      const newUsers = {};

      for (const project of projects) {
        const tasksRef = collection(db, 'tasks');
        const q = query(tasksRef, where('projectId', '==', project.id));
        const taskSnapshot = await getDocs(q);
        
        const memberIds = new Set();
        
        taskSnapshot.docs.forEach(doc => {
          const task = doc.data();
          if (task.assignedUsers && Array.isArray(task.assignedUsers)) {
            task.assignedUsers.forEach(userId => memberIds.add(userId));
          }
          if (task.createdBy) {
            memberIds.add(task.createdBy);
          }
        });

        // Felhasználók adatainak lekérése
        for (const userId of memberIds) {
          if (!newUsers[userId]) {
            const userDoc = await getDoc(doc(db, 'users', userId));
            if (userDoc.exists()) {
              const userData = userDoc.data();
              newUsers[userId] = {
                id: userId,
                displayName: userData.name || userData.email,
                email: userData.email,
                photoURL: userData.photoURL,
                role: userData.role
              };
            }
          }
        }

        newProjectMembers[project.id] = Array.from(memberIds);
      }

      setProjectMembers(newProjectMembers);
      setUsers(prev => ({ ...prev, ...newUsers }));
      
    } catch (error) {
      console.error('Hiba a projekt tagok betöltésekor:', error);
    }
  };

  useEffect(() => {
    if (projects.length > 0) {
      fetchProjectMembers();
    }
  }, [projects]);

  // Pinned projektek betöltése - módosított verzió
  useEffect(() => {
    const loadPinnedProjects = async () => {
      try {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          // Szűrjük a pinnedProjects listát az aktuális szervezet projektjeire
          const allPinnedProjects = userData.pinnedProjects || [];
          const orgPinnedProjects = projects
            .filter(project => project.organizationId === organizationId)
            .filter(project => allPinnedProjects.includes(project.id));
          
          setPinnedProjects(new Set(orgPinnedProjects.map(p => p.id)));
        }
      } catch (error) {
        console.error('Hiba a rögzített projektek betöltésekor:', error);
      }
    };

    if (projects.length > 0) {
      loadPinnedProjects();
    }
  }, [projects, organizationId, currentUser.uid]);

  // Pin/Unpin kezelése
  const handlePinToggle = async (projectId, event) => {
    event.preventDefault(); // Link kattintás megakadályozása
    event.stopPropagation();
    
    try {
      const userRef = doc(db, 'users', currentUser.uid);
      const newPinnedProjects = new Set(pinnedProjects);
      
      if (newPinnedProjects.has(projectId)) {
        newPinnedProjects.delete(projectId);
        toast.success('Projekt eltávolítva a rögzítettek közül');
      } else {
        newPinnedProjects.add(projectId);
        toast.success('Projekt rögzítve');
      }
      
      await updateDoc(userRef, {
        pinnedProjects: Array.from(newPinnedProjects)
      });
      
      setPinnedProjects(newPinnedProjects);
    } catch (error) {
      console.error('Hiba a projekt rögzítésekor:', error);
      toast.error('Hiba történt a művelet során');
    }
  };

  // Rendezzük a projekteket (rögzítettek előre)
  const sortedProjects = [...filteredProjects].sort((a, b) => {
    const aIsPinned = pinnedProjects.has(a.id);
    const bIsPinned = pinnedProjects.has(b.id);
    if (aIsPinned && !bIsPinned) return -1;
    if (!aIsPinned && bIsPinned) return 1;
    return 0;
  });

  // Felhasználók betöltése módosított verzió
  useEffect(() => {
    const loadUsers = async () => {
      setUsersLoading(true);
      try {
        const userIds = [...new Set(projects.flatMap(project => [
          project.createdBy,
          ...(project.members || []),
          project.adminId
        ].filter(Boolean)))];

        const usersData = {};
        await Promise.all(userIds.map(async (userId) => {
          if (!userId) return;
          const userDoc = await getDoc(doc(db, 'users', userId));
          if (userDoc.exists()) {
            usersData[userId] = { id: userId, ...userDoc.data() };
          }
        }));
        setUsers(usersData);
      } catch (error) {
        console.error('Hiba a felhasználók betöltésekor:', error);
      } finally {
        setUsersLoading(false);
      }
    };

    if (projects.length > 0) {
      loadUsers();
    }
  }, [projects]);

  if (loading) {
    return (
      <div className="animate-pulse space-y-4">
        <div className="h-8 bg-gradient-to-r from-indigo-100 to-indigo-50 rounded-lg w-1/3"></div>
        <div className="space-y-3">
          {[...Array(3)].map((_, i) => (
            <div key={i} className="bg-gradient-to-r from-gray-100 to-gray-50 h-32 rounded-lg"></div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {/* Szűrők és nézet váltó */}
      <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-100">
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
          <div className="flex flex-wrap items-center gap-3">
            {/* Keresés */}
            <div className="relative rounded-md shadow-sm min-w-[200px]">
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="block w-full rounded-md border-gray-300 pl-10 pr-3 py-2 text-sm focus:border-indigo-500 focus:ring-indigo-500"
                placeholder="Projekt keresése..."
              />
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                </svg>
              </div>
            </div>

            {/* Státusz szűrők */}
            <div className="flex flex-wrap items-center gap-2">
              {STATUS_FILTERS.map(filter => (
                <button
                  key={filter.value}
                  onClick={() => setStatusFilter(filter.value)}
                  className={`inline-flex items-center px-3 py-1.5 rounded-full text-sm font-medium transition-all duration-200 ${
                    statusFilter === filter.value
                      ? 'bg-indigo-100 text-indigo-700 ring-2 ring-indigo-600 ring-offset-2'
                      : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                  }`}
                >
                  <span className="mr-1.5">{filter.icon}</span>
                  {filter.label}
                  {statusFilter === filter.value && (
                    <span className="ml-1.5 text-xs bg-indigo-200 text-indigo-800 px-1.5 py-0.5 rounded-full">
                      {filteredProjects.length}
                    </span>
                  )}
                </button>
              ))}
            </div>
          </div>

          {/* Nézet váltó */}
          <div className="flex items-center gap-1 bg-gray-100 p-1 rounded-lg">
            <button
              onClick={() => setViewMode('list')}
              className={`p-2 rounded-md transition-all duration-200 ${
                viewMode === 'list'
                  ? 'bg-white text-indigo-600 shadow-sm'
                  : 'text-gray-500 hover:text-indigo-600'
              }`}
            >
              <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 10h16M4 14h16M4 18h16" />
              </svg>
            </button>
            <button
              onClick={() => setViewMode('grid')}
              className={`p-2 rounded-md transition-all duration-200 ${
                viewMode === 'grid'
                  ? 'bg-white text-indigo-600 shadow-sm'
                  : 'text-gray-500 hover:text-indigo-600'
              }`}
            >
              <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Projektek lista/grid nézet */}
      {viewMode === 'list' ? (
        <div className="space-y-4">
          {/* Rögzített projektek szekció */}
          {Array.from(pinnedProjects).length > 0 && (
            <div>
              <h3 className="text-xs font-medium text-indigo-600 uppercase tracking-wider mb-2 flex items-center bg-indigo-50 py-2 px-3 rounded-lg border border-indigo-100">
                <svg className="h-4 w-4 mr-1.5 text-indigo-500" fill="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z" />
                </svg>
                Rögzített projektek
                <span className="ml-2 bg-indigo-100 text-indigo-700 px-2 py-0.5 rounded-full text-xs">
                  {sortedProjects.filter(project => 
                    pinnedProjects.has(project.id) && 
                    project.organizationId === organizationId
                  ).length}
                </span>
              </h3>
              <div className="bg-white rounded-lg shadow-sm border border-gray-100 overflow-hidden">
                <ul className="divide-y divide-gray-100">
                  {sortedProjects
                    .filter(project => pinnedProjects.has(project.id) && project.organizationId === organizationId)
                    .map(project => (
                      <li 
                        key={project.id}
                        className="relative hover:bg-gray-50 transition-colors duration-150 flex"
                      >
                        {/* Bal oldali színes sáv */}
                        <div 
                          className={`w-2.5 ${PROJECT_COLORS[project.color]?.replace('bg-', 'bg-')} opacity-90`}
                        />
                        
                        <Link
                          to={`/organization/${organizationId}/projects/${project.id}`}
                          className="block p-4 flex-1"
                        >
                          <div className="flex items-center justify-between">
                            <div className="flex-1 min-w-0">
                              <div className="flex items-center gap-2">
                                {/* Pin gomb */}
                                <button
                                  onClick={(e) => handlePinToggle(project.id, e)}
                                  className={`p-1 rounded-full hover:bg-gray-100 transition-colors duration-200 ${
                                    pinnedProjects.has(project.id) 
                                      ? 'text-indigo-600' 
                                      : 'text-gray-400 hover:text-indigo-600'
                                  }`}
                                  title={pinnedProjects.has(project.id) ? 'Rögzítés feloldása' : 'Projekt rögzítése'}
                                >
                                  <svg 
                                    className="h-4 w-4" 
                                    fill="none" 
                                    viewBox="0 0 24 24" 
                                    stroke="currentColor"
                                  >
                                    <path 
                                      strokeLinecap="round" 
                                      strokeLinejoin="round" 
                                      strokeWidth={2} 
                                      d={pinnedProjects.has(project.id)
                                        ? "M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
                                        : "M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
                                      }
                                    />
                                  </svg>
                                </button>
                                <h3 className="text-sm font-medium text-gray-900 truncate">
                                  {project.name}
                                </h3>
                                <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-mono text-gray-500 bg-gray-100">
                                  {project.code}
                                </span>
                              </div>
                              
                              {project.description && (
                                <div className="mt-1 text-sm text-gray-500 line-clamp-1"
                                  dangerouslySetInnerHTML={renderHTML(project.description)}
                                />
                              )}

                              <div className="mt-2 flex flex-wrap items-center gap-3">
                                <ProjectStatusBadge status={project.status} />
                                {project.deadline && <DeadlineBadge deadline={project.deadline} />}
                              </div>
                            </div>

                            <div className="ml-4 flex items-center gap-4">
                              {/* Tagok */}
                              <div className="flex -space-x-2">
                                {usersLoading ? (
                                  <div className="flex -space-x-2">
                                    <AvatarSkeleton />
                                    <AvatarSkeleton />
                                  </div>
                                ) : (
                                  projectMembers[project.id]?.slice(0, 3).map(memberId => {
                                    const user = users[memberId];
                                    if (!user) return null;
                                    return (
                                      <div key={memberId} className="relative">
                                        <Avatar user={user} size="sm" />
                                      </div>
                                    );
                                  })
                                )}
                              </div>

                              <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                              </svg>
                            </div>
                          </div>
                        </Link>

                        {/* Jobb oldali színes sáv */}
                        <div 
                          className={`w-2.5 ${PROJECT_COLORS[project.color]?.replace('bg-', 'bg-')} opacity-90`}
                        />
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          )}

          {/* Többi projekt */}
          <div>
            <h3 className="text-xs font-medium text-gray-700 uppercase tracking-wider mb-2 flex items-center bg-gray-50 py-2 px-3 rounded-lg border border-gray-200">
              <svg className="h-4 w-4 mr-1.5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
              </svg>
              Összes projekt
              <span className="ml-2 bg-white text-gray-700 px-2 py-0.5 rounded-full text-xs border border-gray-200">
                {sortedProjects.filter(project => 
                  !pinnedProjects.has(project.id) && 
                  project.organizationId === organizationId
                ).length}
              </span>
            </h3>
            <div className="bg-white rounded-lg shadow-sm border border-gray-100 overflow-hidden">
              <ul className="divide-y divide-gray-100">
                {sortedProjects
                  .filter(project => 
                    !pinnedProjects.has(project.id) && 
                    project.organizationId === organizationId
                  )
                  .map(project => (
                    <li 
                      key={project.id}
                      className="relative hover:bg-gray-50 transition-colors duration-150 flex"
                    >
                      {/* Bal oldali színes sáv */}
                      <div 
                        className={`w-2.5 ${PROJECT_COLORS[project.color]?.replace('bg-', 'bg-')} opacity-90`}
                      />
                      
                      <Link
                        to={`/organization/${organizationId}/projects/${project.id}`}
                        className="block p-4 flex-1"
                      >
                        <div className="flex items-center justify-between">
                          <div className="flex-1 min-w-0">
                            <div className="flex items-center gap-2">
                              {/* Pin gomb */}
                              <button
                                onClick={(e) => handlePinToggle(project.id, e)}
                                className={`p-1 rounded-full hover:bg-gray-100 transition-colors duration-200 ${
                                  pinnedProjects.has(project.id) 
                                    ? 'text-indigo-600' 
                                    : 'text-gray-400 hover:text-indigo-600'
                                }`}
                                title={pinnedProjects.has(project.id) ? 'Rögzítés feloldása' : 'Projekt rögzítése'}
                              >
                                <svg 
                                  className="h-4 w-4" 
                                  fill="none" 
                                  viewBox="0 0 24 24" 
                                  stroke="currentColor"
                                >
                                  <path 
                                    strokeLinecap="round" 
                                    strokeLinejoin="round" 
                                    strokeWidth={2} 
                                    d={pinnedProjects.has(project.id)
                                      ? "M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
                                      : "M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
                                    }
                                  />
                                </svg>
                              </button>
                              <h3 className="text-sm font-medium text-gray-900 truncate">
                                {project.name}
                              </h3>
                              <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-mono text-gray-500 bg-gray-100">
                                {project.code}
                              </span>
                            </div>
                            
                            {project.description && (
                              <div className="mt-1 text-sm text-gray-500 line-clamp-1"
                                dangerouslySetInnerHTML={renderHTML(project.description)}
                              />
                            )}

                            <div className="mt-2 flex flex-wrap items-center gap-3">
                              <ProjectStatusBadge status={project.status} />
                              {project.deadline && <DeadlineBadge deadline={project.deadline} />}
                            </div>
                          </div>

                          <div className="ml-4 flex items-center gap-4">
                            {/* Tagok */}
                            <div className="flex -space-x-2">
                              {usersLoading ? (
                                <div className="flex -space-x-2">
                                  <AvatarSkeleton />
                                  <AvatarSkeleton />
                                </div>
                              ) : (
                                projectMembers[project.id]?.slice(0, 3).map(memberId => {
                                  const user = users[memberId];
                                  if (!user) return null;
                                  return (
                                    <div key={memberId} className="relative">
                                      <Avatar user={user} size="sm" />
                                    </div>
                                  );
                                })
                              )}
                            </div>

                            <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                              <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                            </svg>
                          </div>
                        </div>
                      </Link>

                      {/* Jobb oldali színes sáv */}
                      <div 
                        className={`w-2.5 ${PROJECT_COLORS[project.color]?.replace('bg-', 'bg-')} opacity-90`}
                      />
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      ) : (
        // Grid nézet
        <div className="space-y-4">
          {Array.from(pinnedProjects).length > 0 && (
            <div>
              <h3 className="text-xs font-medium text-indigo-600 uppercase tracking-wider mb-2 flex items-center bg-indigo-50 py-2 px-3 rounded-lg border border-indigo-100">
                <svg className="h-4 w-4 mr-1.5 text-indigo-500" fill="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z" />
                </svg>
                Rögzített projektek
                <span className="ml-2 bg-indigo-100 text-indigo-700 px-2 py-0.5 rounded-full text-xs">
                  {Array.from(pinnedProjects).length}
                </span>
              </h3>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                {sortedProjects
                  .filter(project => pinnedProjects.has(project.id))
                  .map(project => (
                    <Link
                      key={project.id}
                      to={`/organization/${organizationId}/projects/${project.id}`}
                      className="block group h-[200px]"
                    >
                      <div 
                        className={`relative h-full ${PROJECT_COLORS[project.color]?.replace('bg-', 'bg-')} bg-opacity-10 rounded-lg shadow-sm border border-gray-200 overflow-hidden hover:shadow-md transition-all duration-200`}
                      >
                        <div className="absolute inset-0 p-4 flex flex-col">
                          <div className="flex items-center justify-between mb-3">
                            <div className="flex items-center gap-2">
                              <h3 className="text-sm font-medium text-gray-900 group-hover:text-indigo-600 transition-colors duration-150">
                                {project.name}
                              </h3>
                            </div>
                            <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-mono text-gray-500 bg-white bg-opacity-75">
                              {project.code}
                            </span>
                          </div>

                          {project.description && (
                            <div 
                              className="text-sm text-gray-600 line-clamp-2 mb-3"
                              dangerouslySetInnerHTML={renderHTML(project.description)}
                            />
                          )}

                          <div className="flex flex-wrap items-center gap-2 mb-3">
                            <ProjectStatusBadge status={project.status} />
                            {project.deadline && <DeadlineBadge deadline={project.deadline} />}
                          </div>

                          <div className="mt-auto flex items-center justify-between">
                            <div className="flex -space-x-2">
                              {usersLoading ? (
                                <div className="flex -space-x-2">
                                  <AvatarSkeleton />
                                  <AvatarSkeleton />
                                </div>
                              ) : (
                                projectMembers[project.id]?.slice(0, 3).map(memberId => {
                                  const user = users[memberId];
                                  if (!user) return null;
                                  return (
                                    <div key={memberId} className="relative">
                                      <Avatar user={user} size="sm" />
                                    </div>
                                  );
                                })
                              )}
                              {projectMembers[project.id]?.length > 3 && (
                                <span className="inline-flex items-center justify-center h-8 w-8 rounded-full bg-white bg-opacity-75 text-xs font-medium text-gray-600">
                                  +{projectMembers[project.id].length - 3}
                                </span>
                              )}
                            </div>

                            <button
                              onClick={(e) => handlePinToggle(project.id, e)}
                              className={`p-1.5 rounded-full bg-white bg-opacity-75 transition-colors duration-200 ${
                                pinnedProjects.has(project.id) ? 'text-indigo-600' : 'text-gray-500'
                              }`}
                            >
                              <svg 
                                className="h-5 w-5" 
                                fill={pinnedProjects.has(project.id) ? 'currentColor' : 'none'} 
                                stroke="currentColor" 
                                viewBox="0 0 24 24"
                              >
                                <path 
                                  strokeLinecap="round" 
                                  strokeLinejoin="round" 
                                  strokeWidth={2} 
                                  d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))}
              </div>
            </div>
          )}

          <div>
            <h3 className="text-xs font-medium text-gray-700 uppercase tracking-wider mb-2 flex items-center bg-gray-50 py-2 px-3 rounded-lg border border-gray-200">
              <svg className="h-4 w-4 mr-1.5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
              </svg>
              Összes projekt
              <span className="ml-2 bg-white text-gray-700 px-2 py-0.5 rounded-full text-xs border border-gray-200">
                {sortedProjects.filter(project => !pinnedProjects.has(project.id)).length}
              </span>
            </h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {sortedProjects
                .filter(project => !pinnedProjects.has(project.id))
                .map(project => (
                  <Link
                    key={project.id}
                    to={`/organization/${organizationId}/projects/${project.id}`}
                    className="block group h-[200px]"
                  >
                    <div 
                      className={`relative h-full ${PROJECT_COLORS[project.color]?.replace('bg-', 'bg-')} bg-opacity-10 rounded-lg shadow-sm border border-gray-200 overflow-hidden hover:shadow-md transition-all duration-200`}
                    >
                      <div className="absolute inset-0 p-4 flex flex-col">
                        <div className="flex items-center justify-between mb-3">
                          <div className="flex items-center gap-2">
                            <h3 className="text-sm font-medium text-gray-900 group-hover:text-indigo-600 transition-colors duration-150">
                              {project.name}
                            </h3>
                          </div>
                          <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-mono text-gray-500 bg-white bg-opacity-75">
                            {project.code}
                          </span>
                        </div>

                        {project.description && (
                          <div 
                            className="text-sm text-gray-600 line-clamp-2 mb-3"
                            dangerouslySetInnerHTML={renderHTML(project.description)}
                          />
                        )}

                        <div className="flex flex-wrap items-center gap-2 mb-3">
                          <ProjectStatusBadge status={project.status} />
                          {project.deadline && <DeadlineBadge deadline={project.deadline} />}
                        </div>

                        <div className="mt-auto flex items-center justify-between">
                          <div className="flex -space-x-2">
                            {usersLoading ? (
                              <div className="flex -space-x-2">
                                <AvatarSkeleton />
                                <AvatarSkeleton />
                              </div>
                            ) : (
                              projectMembers[project.id]?.slice(0, 3).map(memberId => {
                                const user = users[memberId];
                                if (!user) return null;
                                return (
                                  <div key={memberId} className="relative">
                                    <Avatar user={user} size="sm" />
                                  </div>
                                );
                              })
                            )}
                            {projectMembers[project.id]?.length > 3 && (
                              <span className="inline-flex items-center justify-center h-8 w-8 rounded-full bg-white bg-opacity-75 text-xs font-medium text-gray-600">
                                +{projectMembers[project.id].length - 3}
                              </span>
                            )}
                          </div>

                          <button
                            onClick={(e) => handlePinToggle(project.id, e)}
                            className={`p-1.5 rounded-full bg-white bg-opacity-75 transition-colors duration-200 ${
                              pinnedProjects.has(project.id) ? 'text-indigo-600' : 'text-gray-500'
                            }`}
                          >
                            <svg 
                              className="h-5 w-5" 
                              fill={pinnedProjects.has(project.id) ? 'currentColor' : 'none'} 
                              stroke="currentColor" 
                              viewBox="0 0 24 24"
                            >
                              <path 
                                strokeLinecap="round" 
                                strokeLinejoin="round" 
                                strokeWidth={2} 
                                d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
} 