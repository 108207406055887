import { useState, useEffect } from 'react';
import { collection, getDocs, query, where, deleteDoc, doc, writeBatch, orderBy, limit } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { useAuth } from '../../contexts/AuthContext';
import { isSystemAdmin, addSystemAdmin, INITIAL_ADMIN_UID } from '../../firebase/collections/systemSettings';
import { toast } from 'react-toastify';
import LoadingScreen from '../ui/LoadingScreen';
import { deleteObject } from 'firebase/storage';
import { ref } from 'firebase/storage';
import { storage } from '../../firebase/config';
import { Link, useNavigate } from 'react-router-dom';

// Segédfüggvény a dátum formázásához
const formatDate = (timestamp) => {
  if (!timestamp) return '-';
  
  try {
    // Ha Timestamp objektum
    const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
    
    return new Intl.DateTimeFormat('hu-HU', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).format(date);
  } catch (error) {
    console.error('Dátum formázási hiba:', error);
    return '-';
  }
};

export default function SystemAdminDashboard() {
  const [users, setUsers] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [newAdminEmail, setNewAdminEmail] = useState('');
  const [activities, setActivities] = useState([]);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  async function fetchData() {
    try {
      const isAdmin = await isSystemAdmin(currentUser.uid);
      if (!isAdmin) {
        setError('Nincs jogosultsága az oldal megtekintéséhez');
        return;
      }

      const [usersSnapshot, orgsSnapshot, ticketsSnapshot, projectsSnapshot, tasksSnapshot] = await Promise.all([
        getDocs(collection(db, 'users')),
        getDocs(collection(db, 'organizations')),
        getDocs(query(collection(db, 'tickets'), orderBy('createdAt', 'desc'), limit(50))),
        getDocs(query(collection(db, 'projects'), orderBy('createdAt', 'desc'), limit(50))),
        getDocs(query(collection(db, 'tasks'), orderBy('createdAt', 'desc'), limit(50)))
      ]);

      const users = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      const organizations = orgsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      
      // Összegyűjtjük az összes aktivitást
      const allActivities = [
        ...ticketsSnapshot.docs.map(doc => ({
          id: doc.id,
          type: 'ticket',
          title: doc.data().title,
          createdAt: doc.data().createdAt,
          createdBy: doc.data().createdBy,
          organizationId: doc.data().organizationId
        })),
        ...projectsSnapshot.docs.map(doc => ({
          id: doc.id,
          type: 'project',
          title: doc.data().name,
          createdAt: doc.data().createdAt,
          createdBy: doc.data().createdBy,
          organizationId: doc.data().organizationId
        })),
        ...tasksSnapshot.docs.map(doc => ({
          id: doc.id,
          type: 'task',
          title: doc.data().title,
          createdAt: doc.data().createdAt,
          createdBy: doc.data().createdBy,
          organizationId: doc.data().organizationId
        }))
      ].sort((a, b) => b.createdAt - a.createdAt);

      setUsers(users);
      setOrganizations(organizations);
      setActivities(allActivities);
    } catch (err) {
      setError('Hiba történt az adatok betöltésekor');
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [currentUser]);

  const handleAddAdmin = async (e) => {
    e.preventDefault();
    try {
      const usersQuery = query(
        collection(db, 'users'),
        where('email', '==', newAdminEmail.toLowerCase())
      );
      const userSnapshot = await getDocs(usersQuery);

      if (userSnapshot.empty) {
        toast.error('Felhasználó nem található');
        return;
      }

      const userId = userSnapshot.docs[0].id;
      await addSystemAdmin(userId, currentUser.uid);
      toast.success('Rendszeradmin sikeresen hozzáadva!');
      setNewAdminEmail('');
      fetchData();
    } catch (err) {
      toast.error('Hiba: ' + err.message);
    }
  };

  const handleDeleteOrganization = async (org) => {
    if (!window.confirm(`Biztosan törölni szeretnéd a(z) "${org.name}" szervezetet?\n\nEz törölni fogja az összes kapcsolódó adatot:\n- Projekteket\n- Ticketeket\n- Klienseket\n- Feladatokat\n- Munkaidő bejegyzéseket\n- Kiszállásokat\n- Fájlokat\n- Megjegyzéseket\n\nEz a művelet nem visszavonható!`)) {
      return;
    }

    try {
      setLoading(true);
      const batch = writeBatch(db);
      
      // Projektek törlése
      const projectsRef = collection(db, 'projects');
      const projectsQuery = query(projectsRef, where('organizationId', '==', org.id));
      const projectsSnapshot = await getDocs(projectsQuery);
      projectsSnapshot.docs.forEach(doc => {
        batch.delete(doc.ref);
      });

      // Ticketek törlése
      const ticketsRef = collection(db, 'tickets');
      const ticketsQuery = query(ticketsRef, where('organizationId', '==', org.id));
      const ticketsSnapshot = await getDocs(ticketsQuery);
      ticketsSnapshot.docs.forEach(doc => {
        batch.delete(doc.ref);
      });

      // Kliensek törlése
      const clientsRef = collection(db, 'clients');
      const clientsQuery = query(clientsRef, where('organizationId', '==', org.id));
      const clientsSnapshot = await getDocs(clientsQuery);
      clientsSnapshot.docs.forEach(doc => {
        batch.delete(doc.ref);
      });

      // Feladatok törlése
      const tasksRef = collection(db, 'tasks');
      const tasksQuery = query(tasksRef, where('organizationId', '==', org.id));
      const tasksSnapshot = await getDocs(tasksQuery);
      tasksSnapshot.docs.forEach(doc => {
        batch.delete(doc.ref);
      });

      // Munkaidő bejegyzések törlése
      const workTimesRef = collection(db, 'workTimes');
      const workTimesQuery = query(workTimesRef, where('organizationId', '==', org.id));
      const workTimesSnapshot = await getDocs(workTimesQuery);
      workTimesSnapshot.docs.forEach(doc => {
        batch.delete(doc.ref);
      });

      // Kiszállások törlése
      const visitsRef = collection(db, 'visits');
      const visitsQuery = query(visitsRef, where('organizationId', '==', org.id));
      const visitsSnapshot = await getDocs(visitsQuery);
      visitsSnapshot.docs.forEach(doc => {
        batch.delete(doc.ref);
      });

      // Megjegyzések törlése
      const commentsRef = collection(db, 'comments');
      const commentsQuery = query(commentsRef, where('organizationId', '==', org.id));
      const commentsSnapshot = await getDocs(commentsQuery);
      commentsSnapshot.docs.forEach(doc => {
        batch.delete(doc.ref);
      });

      // Fájlok törlése (Storage-ból is!)
      const filesRef = collection(db, 'files');
      const filesQuery = query(filesRef, where('organizationId', '==', org.id));
      const filesSnapshot = await getDocs(filesQuery);
      
      // Fájlok törlése a Storage-ból
      const deletePromises = filesSnapshot.docs.map(async (doc) => {
        const fileData = doc.data();
        if (fileData.path) {
          const storageRef = ref(storage, fileData.path);
          try {
            await deleteObject(storageRef);
          } catch (error) {
            console.error('Hiba a fájl törlésekor:', error);
          }
        }
        batch.delete(doc.ref);
      });

      // Várjuk meg a fájlok törlését
      await Promise.all(deletePromises);

      // Végül töröljük magát a szervezetet
      const orgRef = doc(db, 'organizations', org.id);
      batch.delete(orgRef);

      // Végrehajtjuk a batch műveletet
      await batch.commit();

      toast.success('Szervezet és minden kapcsolódó adat sikeresen törölve!');
      
      // Frissítjük az adatokat
      fetchData();
    } catch (err) {
      console.error('Hiba a szervezet törlésekor:', err);
      toast.error('Hiba történt a szervezet törlésekor!');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteUser = async (user) => {
    // Ne engedjük a saját felhasználó vagy az eredeti admin törlését
    if (user.id === currentUser.uid) {
      toast.error('Nem törölheted a saját felhasználódat!');
      return;
    }
    if (user.id === INITIAL_ADMIN_UID) {
      toast.error('Az eredeti admin felhasználó nem törölhető!');
      return;
    }

    if (!window.confirm(`Biztosan törölni szeretnéd a következő felhasználót: ${user.email}?\n\nEz törölni fogja az összes kapcsolódó adatot:\n- Munkaidő bejegyzéseket\n- Kiszállásokat\n- Megjegyzéseket\n- Hozzárendelt feladatokat\n\nEz a művelet nem visszavonható!`)) {
      return;
    }

    try {
      setLoading(true);
      const batch = writeBatch(db);

      // Munkaidő bejegyzések törlése
      const workTimesRef = collection(db, 'workTimes');
      const workTimesQuery = query(workTimesRef, where('userId', '==', user.id));
      const workTimesSnapshot = await getDocs(workTimesQuery);
      workTimesSnapshot.docs.forEach(doc => {
        batch.delete(doc.ref);
      });

      // Kiszállások törlése
      const visitsRef = collection(db, 'visits');
      const visitsQuery = query(visitsRef, where('userId', '==', user.id));
      const visitsSnapshot = await getDocs(visitsQuery);
      visitsSnapshot.docs.forEach(doc => {
        batch.delete(doc.ref);
      });

      // Megjegyzések törlése
      const commentsRef = collection(db, 'comments');
      const commentsQuery = query(commentsRef, where('userId', '==', user.id));
      const commentsSnapshot = await getDocs(commentsQuery);
      commentsSnapshot.docs.forEach(doc => {
        batch.delete(doc.ref);
      });

      // Felhasználó törlése a szervezetek members listájából
      const orgsToUpdate = organizations.filter(org => 
        org.members?.includes(user.id) || org.adminId === user.id
      );

      for (const org of orgsToUpdate) {
        const orgRef = doc(db, 'organizations', org.id);
        const updatedMembers = (org.members || []).filter(id => id !== user.id);
        const updatedAdminId = org.adminId === user.id ? null : org.adminId;
        batch.update(orgRef, { 
          members: updatedMembers,
          adminId: updatedAdminId
        });
      }

      // Felhasználó dokumentum törlése
      const userRef = doc(db, 'users', user.id);
      batch.delete(userRef);

      // Batch művelet végrehajtása
      await batch.commit();

      toast.success('Felhasználó és kapcsolódó adatok sikeresen törölve!');
      
      // Frissítjük az adatokat
      fetchData();
    } catch (err) {
      console.error('Hiba a felhasználó törlésekor:', err);
      toast.error('Hiba történt a felhasználó törlésekor!');
    } finally {
      setLoading(false);
    }
  };

  const handleActivityClick = (activity) => {
    switch (activity.type) {
      case 'ticket':
        navigate(`/organization/${activity.organizationId}/tickets/${activity.id}`);
        break;
      case 'project':
        navigate(`/organization/${activity.organizationId}/projects/${activity.id}`);
        break;
      case 'task':
        navigate(`/organization/${activity.organizationId}/tasks/${activity.id}`);
        break;
      default:
        break;
    }
  };

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {/* Breadcrumb */}
      <nav className="mb-8 bg-white shadow-sm rounded-lg px-4 py-2" aria-label="Breadcrumb">
        <ol className="flex items-center space-x-2 text-[8pt] text-gray-500">
          <li className="flex items-center">
            <Link to="/" className="text-indigo-600 hover:text-indigo-900 transition-colors duration-150 ease-in-out">
              <svg className="h-3 w-3" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
              </svg>
            </Link>
          </li>
          <li><svg className="h-3 w-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg></li>
          <li className="text-gray-700 font-medium">Rendszeradmin</li>
        </ol>
      </nav>

      {/* Admin hozzáadása form */}
      <div className="bg-white shadow sm:rounded-lg mb-8">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Új rendszeradmin hozzáadása
          </h3>
          <form onSubmit={handleAddAdmin} className="mt-5 sm:flex sm:items-center">
            <div className="w-full sm:max-w-xs">
              <input
                type="email"
                value={newAdminEmail}
                onChange={(e) => setNewAdminEmail(e.target.value)}
                placeholder="admin@example.com"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <button
              type="submit"
              className="mt-3 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Hozzáadás
            </button>
          </form>
        </div>
      </div>

      {/* Szervezetek táblázat */}
      <div className="mt-8">
        <h2 className="text-xl font-semibold mb-4">Szervezetek ({organizations.length})</h2>
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Név
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Admin
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Tagok száma
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Létrehozva
                      </th>
                      <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Műveletek
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {organizations.map((org) => (
                      <tr key={org.id}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">{org.name}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-500">
                            {users.find(u => u.id === org.adminId)?.email || 'N/A'}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-500">
                            {org.members?.length || 0}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-500">
                            {new Date(org.createdAt).toLocaleDateString()}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <button
                            onClick={() => handleDeleteOrganization(org)}
                            className="text-red-600 hover:text-red-900 focus:outline-none"
                          >
                            <svg 
                              xmlns="http://www.w3.org/2000/svg" 
                              className="h-5 w-5" 
                              fill="none" 
                              viewBox="0 0 24 24" 
                              stroke="currentColor"
                            >
                              <path 
                                strokeLinecap="round" 
                                strokeLinejoin="round" 
                                strokeWidth={2} 
                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" 
                              />
                            </svg>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Felhasználók táblázat */}
      <div className="mt-8">
        <h2 className="text-xl font-semibold mb-4">Felhasználók ({users.length})</h2>
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Email
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Regisztráció dátuma
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Szervezetek száma
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Státusz
                      </th>
                      <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Műveletek
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {users.map((user) => (
                      <tr key={user.id}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {user.email}
                            {user.id === currentUser.uid && " (Te)"}
                            {user.id === INITIAL_ADMIN_UID && " (Rendszeradmin)"}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-500">
                            {formatDate(user.createdAt)}
                          </div>
                          {user.lastLogin && (
                            <div className="text-xs text-gray-400 mt-1">
                              Utolsó belépés: {formatDate(user.lastLogin)}
                            </div>
                          )}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-500">
                            {organizations.filter(org => 
                              org.members?.includes(user.id) || org.adminId === user.id
                            ).length}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full 
                            ${user.disabled ? 'bg-red-100 text-red-800' : 'bg-green-100 text-green-800'}`}>
                            {user.disabled ? 'Letiltva' : 'Aktív'}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          {user.id !== currentUser.uid && user.id !== INITIAL_ADMIN_UID && (
                            <button
                              onClick={() => handleDeleteUser(user)}
                              className="text-red-600 hover:text-red-900 focus:outline-none"
                            >
                              <svg 
                                xmlns="http://www.w3.org/2000/svg" 
                                className="h-5 w-5" 
                                fill="none" 
                                viewBox="0 0 24 24" 
                                stroke="currentColor"
                              >
                                <path 
                                  strokeLinecap="round" 
                                  strokeLinejoin="round" 
                                  strokeWidth={2} 
                                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" 
                                />
                              </svg>
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Legutóbbi aktivitások */}
      <div className="mt-8">
        <h2 className="text-lg sm:text-xl font-semibold mb-4">Legutóbbi aktivitások</h2>
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          <ul className="divide-y divide-gray-200">
            {activities.map((activity) => (
              <li 
                key={`${activity.type}-${activity.id}`}
                onClick={() => handleActivityClick(activity)}
                className="px-4 py-4 hover:bg-gray-50 cursor-pointer transition-colors duration-150"
              >
                <div className="flex items-center justify-between">
                  <div className="flex-1 min-w-0">
                    <div className="flex items-center space-x-3">
                      <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                        ${activity.type === 'ticket' ? 'bg-blue-100 text-blue-800' :
                          activity.type === 'project' ? 'bg-green-100 text-green-800' :
                          'bg-purple-100 text-purple-800'}`}
                      >
                        {activity.type === 'ticket' ? 'Bejelentés' :
                         activity.type === 'project' ? 'Projekt' : 'Feladat'}
                      </span>
                      <p className="text-sm font-medium text-gray-900 truncate">
                        {activity.title}
                      </p>
                    </div>
                    <div className="mt-1">
                      <p className="text-xs text-gray-500">
                        Létrehozva: {new Date(activity.createdAt).toLocaleDateString('hu-HU')}
                      </p>
                    </div>
                  </div>
                  <div className="ml-4">
                    <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                    </svg>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
} 